<div class="fx-fill fx-layout-col">
  <div class="sheet-header">
    <div class="fx-layout-row fx-justify-start fx-align-baseline">
      <div class="sheet-title fx-flex-85-percent">
        <span>{{ entity?.toString() }}</span>
      </div>
      <div class="fx-flex-48">
        <div class="btn-container">
          <button (click)="closeSidePanel()" [disableRipple]="true"
                  class="fx-layout-col fx-justify-center fx-align-center"
                  id="close-sheet-button" mat-icon-button>
            <i class="mdi mdi-close sm"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="fx-layout-row">
      <restricted-icon-button (onClick)="uploadContractDocuments()"
                              [class]="'mdi mdi-paperclip sm'"
                              [matTooltip]="'BUTTON.ATTACH_FILE' | translate"
                              [permissions]="[Permission.EDIT_CONTRACT, Permission.CREATE_DOCUMENT]">
      </restricted-icon-button>

      <restricted-icon-button (onClick)="renewContract()"
                              [class]="'mdi mdi-autorenew sm'"
                              [id]="'renew-contract-button'"
                              [matTooltip]="'BUTTON.RENEW' | translate"
                              [permissions]="[Permission.CREATE_CONTRACT]">
      </restricted-icon-button>

      <restricted-icon-button (onClick)="deleteContract()"
                              [class]="'mdi mdi-delete sm'"
                              [id]="'delete-contract-button'"
                              [matTooltip]="'BUTTON.DELETE' | translate"
                              [permissions]="[Permission.DELETE_CONTRACT]">
      </restricted-icon-button>

      <span *ngIf="displayAssetSheetLink" class="fx-flex"></span>

      <div *ngIf="displayAssetSheetLink" class="asset-sheet-link">
        <span (click)="navigateToAssetSheet()"> {{ 'BUTTON.SHOW_ASSET_SHEET' | translate }} </span>
      </div>
    </div>
  </div>
  <div class="sidebar-sheet fx-flex-grow" cdk-scrollable>
    <div class="page-sidebar">
      <div class="form-container">
        <form-builder *ngIf="entity"
                      [formId]="formId"
                      [eventsOrigin]="eventsOrigin"
                      [permissionsForEdition]="permissionsForEdition"
                      [fieldsToHide]="fieldsToHide">
        </form-builder>
      </div>
      <div class="document-title fx-layout-row fx-justify-start fx-align-center">
        <span class="fx-flex-30-percent"> {{'LABEL.DOCUMENT' | translate}} </span>
        <restricted-text-button
          (onClick)="uploadContractDocuments()"
          [id]="'contract-upload-document-button'"
          [permissions]="[Permission.EDIT_CONTRACT, Permission.CREATE_DOCUMENT]"
          class="btn-upload-document">
          {{'BUTTON.ATTACH_FILE' | translate}}
        </restricted-text-button>
      </div>
      <div *ngFor="let document of entity?.documents" class="document-content">
        <a *ngIf="accessManager.hasAccess(Permission.EXPORT_DOCUMENT); else noExportPermission"
           (click)="fileService.downloadFile(document.id)">
          <i class="mdi mdi-file-outline xs"></i>
          <span class="file-name-label">{{document.name}}</span>
        </a>
        <ng-template #noExportPermission>
          <span class="file-name-label">{{document.name}}</span>
        </ng-template>
        <span (click)="deleteContractDocument(document)"
              [permission-lock]="[Permission.EDIT_CONTRACT, Permission.DELETE_DOCUMENT]"
              class="delete-document-icon">
              <i class="mdi mdi-delete xs"></i>
            </span>
      </div>
    </div>
  </div>
</div>
