<div class="inline-field-container">

  <ng-container *ngIf="!editMode; else input">
    <ng-container *ngIf="control?.value; else empty">
      <div (click)="makeEditable()" (keyup.enter)="makeEditable()"
           class="field-container fx-layout-row fx-wrap fx-justify-start fx-align-baseline" tabindex="0">
        <div [ngClass]="{'disabled': disabled}" class="read-value">
          <span (click)="makeEditable()" [class.selectable]="full === true" class="inline-edit-value">
            {{ control?.value | fieldFormatType: 'numeric' }}
          </span>
          <span *ngIf="suffix">{{ suffix }}</span>
          <div (click)="makeEditable()" *ngIf="!disabled" class="inline-edit-content">
            <span class="overlay-icon">
              <i class="mdi mdi-pencil selectable"></i>
            </span>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #empty>
    <div class="empty-field-container">
      <div [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
           class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
        <div class="field-container fx-flex-100-66-percent fx-layout-col">
          <div class="empty-value">
            <ng-container
              *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition); else noPermissions">
              <add-field-link (click)="onActivate($event)" (keyup.enter)="onActivate($event)"
                              [inputMessage]="'LABEL.EMPTY_' + fieldCode.toUpperCase()"></add-field-link>
            </ng-container>
            <ng-template #noPermissions>
              {{ appConfig.EMPTY_FIELD_VALUE }}
            </ng-template>
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="saveOptions"></ng-container>
    </div>
  </ng-template>

  <ng-template #input>
    <div class="field-container fx-flex-100-percent fx-layout-col">
      <form autocomplete="off">
        <mat-form-field [floatLabel]="'never'" appearance="outline">
          <input #inputField (beforeinput)="validateKeypressEvent($event)" (focus)="onFocus()"
                 (keyup.enter)="onKey($event)" (keyup.escape)="onKey($event)" (paste)="validatePasteEvent($event)"
                 [formControl]="control" class="edit-value" matInput tabindex="0" type="text">
          <span *ngIf="suffix" matSuffix>{{ suffix }}</span>
          <mat-error *ngFor="let validator of validators" class="small-font">
              <span *ngIf="control.hasError(validator.type)">
                {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
              </span>
          </mat-error>
        </mat-form-field>
      </form>
      <ng-container *ngTemplateOutlet="saveOptions"></ng-container>
    </div>
  </ng-template>

  <ng-template #saveOptions>
    <div *ngIf="editMode" class="save-options">
      <button (click)="cancelEditable()" (keyup.escape)="onKey($event)" class="button">
        <i class="mdi mdi-close"></i>
      </button>
      <button (click)="callSave()" (keyup.escape)="onKey($event)" [disabled]="preventSave()"
              class="button save">
        <i class="mdi mdi-check"></i>
      </button>
    </div>
  </ng-template>
</div>
