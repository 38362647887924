import { Component } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { AbstractInlineEditComponent } from '@app/shared/components/inline-edit/inline-edit.component';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';

@Component({
  selector: 'inline-edit-client',
  templateUrl: './inline-edit-client.component.html',
  styleUrls: ['./inline-edit.component.scss']
})
export class InlineEditClientComponent extends AbstractInlineEditComponent {

  constructor(protected validationService: ValidationService,
              protected formStateService: FormStateService,
              protected singleEditService: SingleEditService,
              protected analyticsService: AnalyticsService) {
    super(validationService, singleEditService, analyticsService);
  }
}
