import { InjectionToken } from '@angular/core';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { FieldTypeEnum } from '@app/core/enums/field-type-enum';
import { AbstractBean } from '@app/core/model/abstract-bean';
import { Entity } from '@app/core/model/entities/entity';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { StylesConditions } from '@app/shared/extra/utils';
import { Expose, Type } from 'class-transformer';

export const FIELD_GROUP_CONFIG_INJECTION = new InjectionToken<FieldGroup>('field_group_config_injection');
export const FIELD_CONFIG_INJECTION = new InjectionToken<FieldConfig>('field_config_injection');
export const FIELD_ENTITY_INJECTION = new InjectionToken<Entity>('field_entity_injection');
export const FIELD_PRECONDITIONS_INJECTION = new InjectionToken<boolean>('field_preconditions_injection');
export const FIELD_ASYNC_PRECONDITIONS_INJECTION = new InjectionToken<boolean>('field_async_preconditions_injection');
export const FIELD_PERMISSIONS_INJECTION = new InjectionToken<string[]>('field_permissions_injection');
export const FIELD_EXTRA_DATA = new InjectionToken<any>('field_data_injection');
export const FIELD_EVENTS_ORIGIN = new InjectionToken<any>('field_events_origin');

export interface FormNode {
  label?: string;
  hierarchy?: string[];
  graphqlTypename: string;
}

export interface Form extends FormNode {
  code: string;
  label?: string;
  entityType: EntityTypeEnum;
  sections: Section[];
}
export class Section extends AbstractBean implements FormNode {
  public code: string;
  public order: number;
  public formCode: string;
  public organizationId: string;

  public label?: string;
  public emptyLabel?: string;

  public customOptions?: Record<string, any>;

  @Expose({name: '__typename'})
  public graphqlTypename: string;
  public hierarchy: string[];

  @Expose({name: 'fieldGroupsList'})
  @Type(() => FieldGroup)
  public fieldGroups: FieldGroup[];
  @Expose({name: 'conditionsToViewList'})
  public conditionsToView: Condition[];
  @Expose({name: 'conditionsToEditList'})
  public conditionsToEdit: Condition[];
}

/**
 * SectionInputs for creating and updating Sections.
 */
export type UpdateSectionInput = {
  label: string;
};
export type CreateSectionInput = {
  code: string;
  label: string;
  order: number;
  entityType: EntityTypeEnum;
  formCode: string;
};

export class FieldGroup extends AbstractBean implements FormNode {
  public code: string;
  public order: number;
  public fieldGroupType: string;

  public label: string;
  public emptyLabel?: string;
  public tooltip?: string;

  public customOptions?: Record<string, any>;

  @Expose({name: '__typename'})
  public graphqlTypename: string;
  public hierarchy: string[];

  @Expose({name: 'fieldConfigsList'})
  @Type(() => FieldConfig)
  public fieldConfigs: FieldConfig[];
  @Expose({name: 'conditionsToViewList'})
  public conditionsToView: Condition[];
  @Expose({name: 'conditionsToEditList'})
  public conditionsToEdit: Condition[];

  /**
   * Path to the properties referenced by this field group within the current entity.
   */
  public get propertiesPath(): string {
    return this.customOptions?.['propertiesPath'];
  }
}

export class FieldConfig extends AbstractBean implements FormNode {
  public fieldCode: string;
  public order: number;

  public customOptions?: Record<string, any>;

  @Expose({name: '__typename'})
  public graphqlTypename: string;
  public hierarchy: string[];

  @Type(() => Field)
  public field: Field;
  @Expose({name: 'conditionsToViewList'})
  public conditionsToView: Condition[];
  @Expose({name: 'conditionsToEditList'})
  public conditionsToEdit: Condition[];


  /**
   * Retrieves the label for a pictogram based on the provided key.
   * @param {string} key The key used to identify the specific pictogram.
   * @returns {string} The pictogram associated with the given key. Returns undefined if the key is not found.
   */
  public pictogram(key: string): string {
    return this.customOptions?.['pictograms'][key];
  }

  /**
   * Field code associated with this FieldConfig.
   */
  public get code(): string {
    return this.fieldCode;
  }

  /**
   * List of pictograms to use if exists.
   * @returns {object} Returns an object that contains pairs of key/value.
   * The value is the name of the pictogram to render.
   */
  public get pictograms(): object {
    return this.customOptions?.['pictograms'];
  }

  /**
   * Boolean that returns true if the field contains pictograms.
   * @returns {boolean} Returns true if the field contains pictograms, false otherwise.
   */
  public get hasPictograms(): boolean {
    return !!this.customOptions?.['pictograms'];
  }

  /**
   * Boolean that returns true if the field should be displayed with a pictogram and a text.
   * @returns {boolean} Returns true if the field's pictogram should be displayed with a text, false otherwise.
   */
  public get isPictogramWithText(): boolean {
    return this.customOptions?.['withText'] ?? false;
  }

  /**
   * Field's entityType.
   */
  public get fieldEntityType(): EntityTypeEnum {
    return this.field.entityType;
  }

  /**
   * Field's label.
   */
  public get label(): string {
    return this.field.label;
  }

  /**
   * Prefixed translation key of the field group's tooltip text, or undefined if the field group has no tooltip.
   */
  public get tooltip(): string {
    return this.field.tooltip;
  }

  /**
   * Prefixed translation key of the field group's suffix (i.e. unit), or undefined if the field group has no suffix.
   */
  public get suffixType(): string {
    return this.customOptions?.['suffixType'] && 'SUFFIX.' + this.customOptions?.['suffixType'].toUpperCase();
  }

  /**
   * Return the field's path as an Array of string
   */
  public get fieldPath(): string[] {
    return this.field.parentPath.concat(this.fieldCode);
  }

  /**
   * Whether the field is read-only (not editable)
   */
  public get computed(): boolean {
    return !!this.field.computed;
  }

  /**
   * CSS properties that should be applied to Field/Section/Cell in conditional form
   * @return Object containing css properties as keys and a list of condition/value as value
   */
  public get customCss(): StylesConditions {
    return this.customOptions?.['customCss'] ?? {};
  }

  /**
   * Returns an array of cell classes associated with the current field configuration.
   *
   * @return An array of cell classes.
   */
  public get cellClasses(): string[] {
    return this.customOptions?.['cellClasses'] ?? [];
  }
}

export type ComputedField = 'ONLY_COMPUTED' | 'NOT_COMPUTED' | 'ALL';

export class Field extends AbstractBean {
  public code: string;
  public fieldType: FieldTypeEnum;
  public checkType: string;
  public organizationId: string;
  public computed: boolean;
  @Expose({name: 'fieldValuesList'})
  public fieldValues?: any[];
  @Expose({name: 'validatorsList'})
  @Type(() => FieldValidator)
  public validators?: FieldValidator[];
  @Expose({name: 'parentPathList'})
  public parentPath: string[];
  public formula?: JSON;

  public label: string;
  public tooltip?: string;

  /**
   * Return the field's path as an Array of string
   */
  public get fieldPath(): string[] {
    return this.parentPath.concat(this.code);
  }
}

/**
 * FieldInputs for creating and updating Fields.
 */
export type UpdateFieldInput = {
  label: string;
  tooltip: string;
};
export type CreateFieldInput = UpdateFieldInput & ({
  code: string;
  entityType: string;
  validators: FieldValidator[];
} & ({
  fieldValues: string[];
  fieldType: Extract<FieldTypeEnum, FieldTypeEnum.LIST | FieldTypeEnum.NUMERIC_LIST>;
} | {
  fieldType: Exclude<FieldTypeEnum, FieldTypeEnum.LIST | FieldTypeEnum.NUMERIC_LIST>;
}));
export type FieldConfigInput = {
  tooltip?: string;
  emptyLabel?: string;
  suffixType?: string;
  formatType?: FormatType;
  extraCustomOptions?: Record<string, any>;
};

export interface Condition {
  field: string;
  operator: ValidatorConditionOperator;
  value?: any;
  applyValue?: string; // Value to apply if condition is true
}

export interface FieldLocationInformation {
  code: string;
  label: string;
  entityType: string;
  currency: string;
  classificationType: string;
  isTb: boolean;
}

export declare type FormatType =
  'integer'
  | 'percent'
  | 'precise_number'
  | 'numeric'
  | 'duration'
  | 'scientific'
  | 'engineering';

export declare type ValidatorType =
  'MAX_LENGTH'
  | 'REQUIRED'
  | 'MIN_VALUE'
  | 'MAX_VALUE'
  | 'INTEGER'
  | 'MAX_NOW'
  | 'PERCENT'
  | 'YEAR'
  | 'DATE'
  | 'DATE_TIME'
  | 'DECIMAL'
  | 'MISSING'
  | 'REQUIRED_TRUE'
  | 'REQUIRED_FALSE'
  | 'AFTER_DATE'
  | 'BEFORE_DATE'
  | 'INCOHERENT_SPACES'
  | 'GTE_TOTAL_EXPENSES'
  | 'LTE_TOTAL_COSTS'
  | 'END_DATE_IS_BEFORE_TODAY'
  | 'END_DATE_IS_AFTER_TODAY'
  | 'END_DATE_IS_UNDEFINED'
  | 'REALISATION_DATE_IS_AFTER_TODAY'
  | 'REALISATION_DATE_IS_BEFORE_TODAY'
  | 'RENEW_DATE_IS_AFTER_TODAY'
  | 'RENEW_DATE_IS_BEFORE_TODAY'
  | 'RENEW_DATE_IS_UNDEFINED'
  | 'UNIQUE'
  | 'MAX_LENGTH_FILE_NAME'
  | 'EQUAL_TO'
  | 'REGEX'
  | 'DISTINCT'
  | 'GTE_SPACE_CHILDREN_PROPERTY'
  | 'LTE_SPACE_PARENT_PROPERTY'
  | 'ASSET_VALUE_GTE_SPACE_CHILDREN_PROPERTY'
  | 'PROJECT_HAS_INACCESSIBLE_ASSETS'
  | 'IS_SPACE_MOVE_ALLOWED'
  | 'NOT_AVAILABLE'
  | 'READ_ONLY'
| FieldDependantValidatorType;

const FieldDependantValidators = ['GTE_FIELD', 'LTE_FIELD', 'AFTER_OTHER_DATE', 'BEFORE_OTHER_DATE'] as const;
export declare type FieldDependantValidatorType = typeof FieldDependantValidators[number];

/**
 * Check if a validatorType is field dependant or not
 * @param type : validator type
 *
 * @return true if validatorType is fieldDependant otherwise false
 */
export function isValidatorFieldDependant(type: ValidatorType): boolean {
  return FieldDependantValidators.includes(type as FieldDependantValidatorType);
}

export enum ValidatorConditionOperator {
  EMPTY = 'EMPTY',
  EXISTS = 'EXISTS',
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  NEVER = 'NEVER',
  ALWAYS = 'ALWAYS'
}
