import { FieldTypeEnum } from '@app/core/enums/field-type-enum';
import { ValidatorType } from '@app/core/model/other/field-config';

const validatorFieldTypeMapping = (fieldType: FieldTypeEnum): {validator: ValidatorType, hasDefinition: boolean}[] => {
  const validators: {validator: ValidatorType, hasDefinition: boolean}[] = [
    {validator: 'REQUIRED', hasDefinition: false} // Required validators is available to all field types
  ];

  if ([FieldTypeEnum.TEXT, FieldTypeEnum.TEXTAREA,
    FieldTypeEnum.TEXT_AUTOCOMPLETE, FieldTypeEnum.SUGGESTBOX,
    FieldTypeEnum.CHIPS, FieldTypeEnum.CHIPS_AUTOCOMPLETE].includes(fieldType)) {
    validators.push(
      {validator: 'MAX_LENGTH', hasDefinition: true},
      {validator: 'UNIQUE', hasDefinition: false}
    );
  }
  if ([FieldTypeEnum.NUMERIC].includes(fieldType)) {
    validators.push(
      {validator: 'MIN_VALUE', hasDefinition: true},
      {validator: 'MAX_VALUE', hasDefinition: true},
      {validator: 'INTEGER', hasDefinition: false}
    );
  }
  if ([FieldTypeEnum.LIST, FieldTypeEnum.NUMERIC_LIST, FieldTypeEnum.LIST_MULTIVALUE].includes(fieldType)) {
    validators.push(
      {validator: 'MISSING', hasDefinition: false}
    );
  }
  if ([FieldTypeEnum.CHIPS, FieldTypeEnum.CHIPS_AUTOCOMPLETE, FieldTypeEnum.LIST_MULTIVALUE].includes(fieldType)) {
    validators.push(
      {validator: 'DISTINCT', hasDefinition: false}
    );
  }
  if ([FieldTypeEnum.DATE, FieldTypeEnum.DATETIME].includes(fieldType)) {
    validators.push(
      {validator: 'MAX_NOW', hasDefinition: false},
      {validator: 'AFTER_DATE', hasDefinition: true},
      {validator: 'BEFORE_DATE', hasDefinition: true}
    );
  }
  if ([FieldTypeEnum.YEAR].includes(fieldType)) {
    validators.push(
      {validator: 'MIN_VALUE', hasDefinition: true},
      {validator: 'MAX_VALUE', hasDefinition: true}
    );
  }
  if ([FieldTypeEnum.RADIOBUTTON].includes(fieldType)) {
    validators.push();
  }
  if ([FieldTypeEnum.LINK, FieldTypeEnum.EMAIL].includes(fieldType)) {
    validators.push(
      {validator: 'REGEX', hasDefinition: true} // How to implement visually?
      /*
      TODO V2
      string contains, startsWith or endsWith definition?
       */
    );
  }

  /*
  TODO V2
  Some validators reference other fields and will be added to their corresponding lists in V2
  - requiredTrue
  - requiredFalse
  - equalTo
  - notEqualTo
  - afterDateField,
  - beforeDateField
  - gteField
  - lteField
   */

  return validators;
};

export default validatorFieldTypeMapping;
