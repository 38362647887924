import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Equipment, EquipmentInput } from '@app/core/model/entities/equipments/equipment';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { BaseEquipmentSheet } from '@app/features/main/views/equipments/equipment-sheet/base-equipment-sheet';
import {
  EquipmentModalService
} from '@app/features/main/views/equipments/equipments-inventory/modals/equipment-modal.service';
import { EquipmentsService } from '@app/features/main/views/equipments/equipments.service';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { TranslateService } from '@ngx-translate/core';
import { FieldService } from '@services/field.service';
import { FileService } from '@services/file.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { of } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'equipments-sidebar',
  templateUrl: './equipments-sidebar.component.html',
  styleUrls: ['./equipments-sidebar.component.scss'],
  providers: [DocumentModalService, EquipmentModalService]
})
export class EquipmentsSidebarComponent extends BaseEquipmentSheet implements OnInit {

  @Input() public formId: string;
  public eventsOrigin = EventOriginEnum.SIDEPANEL;

  public validators: FieldValidator[] = [];

  constructor(accessManager: AccessManager,
              appConfig: AppConfig,
              appManager: AppManager,
              equipmentsService: EquipmentsService,
              fileService: FileService,
              analyticsService: AnalyticsService,
              equipmentModalService: EquipmentModalService,
              documentsService: DocumentsService,
              formStateService: FormStateService,
              translate: TranslateService,
              snackbarManager: SnackbarManager,
              private fieldService: FieldService,
              private singleEditService: SingleEditService,
              private router: Router) {
    super(
      accessManager,
      appConfig,
      equipmentsService,
      fileService,
      appManager,
      analyticsService,
      equipmentModalService,
      documentsService,
      formStateService,
      translate,
      snackbarManager
    );
  }

  /**
   * Listen for sidebar toggle actions and updates from the FormStateService.
   * Update sidebar data and call Equipment service when necessary.
   */
  public ngOnInit(): void {
    super.ngOnInit();

    // Update the side panel's content whenever another Equipment is selected
    this.equipmentsService.sidePanelToggle$
      .pipe(
        takeUntil(this.destroy$),
        switchMap( equipmentData => {
          return this.fieldService.getField('name', 'EQUIPMENT')
            .pipe(
              map(field => {
                this.validators = field.validators;
                return equipmentData;
              })
            );
        }),
        switchMap(equipmentData => {
          if (equipmentData) {
            return this.equipmentsService.equipmentUpdated$
              .pipe(
                takeUntil(this.destroy$),
                filter(updatedEquipment => updatedEquipment.id === equipmentData.id),
                startWith(equipmentData)
              );
          } else {
            return of(equipmentData);
          }
        }),
        tap(() => {
          this.singleEditService.singleEditSubject.next(null);

          // Unload current Equipment
          if (this.appManager.currentEntity instanceof Equipment) {
            this.appManager.unloadCurrentEntity();
          }
        }),
        filter(equipment => !!equipment)
      )
      .subscribe(equipment => {
        this.equipment = equipment;
        this.images = [];
        this.appManager.currentEntity = equipment;
        this.loadEquipmentData(true);
      });
  }

  /**
   * Open a dialog to delete the current Equipment entity if the user confirms.
   */
  public deleteEquipment(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.EQUIPMENT,
      this.equipment.id
    );
    this.equipmentModalService.openDeleteEquipmentsDialog([this.equipment])
      .pipe(
        takeUntil(this.destroy$),
        filter(result => !!result)
      )
      .subscribe(() => this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.EQUIPMENT_DELETED')));
  }

  /**
   * Open the duplicate Equipment dialog then copy current Equipment.
   */
  public duplicateEquipments(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.DUPLICATE_DIALOG,
      EntityTypeEnum.EQUIPMENT,
      this.equipment.id
    );
    this.equipmentModalService.openDuplicateEquipmentsDialog([this.equipment])
      .pipe(
        takeUntil(this.destroy$),
        filter(result => !!result)
      )
      .subscribe((equipments) => {
        this.router.navigate([
          'organization',
          this.appManager.currentOrganization.id,
          'equipments',
          'equipment-sheet',
          equipments[0].id
        ])
          .catch(console.error);
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.EQUIPMENT_DUPLICATED'));
      });
  }

  /**
   * Close the sidebar.
   */
  public closeEquipmentSidebar(): void {
    this.equipmentsService.closeEquipmentSidePanel();
  }

  /**
   * Bypass Material escape handler to call closeEquipmentSidebar,
   * which manage the current entity stacks.
   * @param event
   */
  @HostListener('keyup', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeEquipmentSidebar();
    }
  }

  /**
   * Navigate to the Equipment's sheet.
   */
  public async navigateToEquipmentSheet(): Promise<void> {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.SHEET,
      EntityTypeEnum.EQUIPMENT,
      this.equipment.id
    );
    await this.equipmentsService.navigateToEquipmentSheet(this.equipment.id);
  }

  /**
   * Open a dialog to upload Documents and link updated Documents to the Equipment entity.
   */
  public uploadEquipmentDocuments(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.UPLOAD_DIALOG,
      EntityTypeEnum.EQUIPMENT,
      this.equipment.id
    );
    super.uploadEquipmentDocuments();
  }

  /**
   * Open a dialog for the user to upload pictures of the Equipment.
   */
  public uploadEquipmentPictures(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.UPLOAD_DIALOG,
      EntityTypeEnum.EQUIPMENT,
      this.equipment.id
    );
    super.uploadEquipmentPictures();
  }

  public saveHeader(event: { value: any }): void {
    this.equipmentsService.updateEquipment(this.equipment, {name: event.value} as EquipmentInput)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: _ => {
          this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.EDIT_SAVED'));
        }
      });
  }
}
