<div class="fx-fill fx-layout-col">
  <div class="sheet-header">
    <div class="fx-layout-row fx-justify-start fx-align-baseline">
      <div class="sheet-title fx-flex-85-percent">
        <span>{{ work?.toString() }}</span>
      </div>
      <div class="fx-flex-48">
        <div class="btn-container">
          <button (click)="closeWorkSidebar()" [disableRipple]="true" class="fx-layout-col fx-justify-center fx-align-center"
                  id="close-sheet-button" mat-icon-button>
            <i class="mdi mdi-close sm"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="fx-layout-row">
      <restricted-icon-button (onClick)="uploadWorkDocuments()"
                              [class]="'mdi mdi-paperclip sm'"
                              [id]="'upload-work-document-button'"
                              [matTooltip]="'BUTTON.ADD_DOCUMENT' | translate"
                              [permissions]="[Permission.EDIT_WORK, Permission.CREATE_DOCUMENT]">
      </restricted-icon-button>
      <restricted-icon-button (onClick)="uploadWorkPictures()"
                              [class]="'mdi mdi-image-plus sm'"
                              [id]="'upload-work-picture-button'"
                              [matTooltip]="'BUTTON.ADD_PICTURE' | translate"
                              [permissions]="[Permission.EDIT_WORK, Permission.CREATE_DOCUMENT]">
      </restricted-icon-button>
      <restricted-icon-button (onClick)="deleteWork()"
                              [class]="'mdi mdi-delete sm'"
                              [id]="'delete-work-button'"
                              [matTooltip]="'BUTTON.DELETE' | translate"
                              [permissions]="[Permission.DELETE_WORK]">
      </restricted-icon-button>
      <restricted-icon-button (onClick)="duplicateWork()"
                              [class]="'mdi mdi-content-copy sm'"
                              [id]="'duplicate-work-button'"
                              [matTooltip]="'BUTTON.DUPLICATE' | translate"
                              [permissions]="[Permission.CREATE_WORK]">
      </restricted-icon-button>

      <span class="fx-flex"></span>

      <div class="work-sheet-link">
        <span (click)="navigateToWorkSheet()">{{ 'BUTTON.SHOW_WORK_SHEET' | translate }}</span>
      </div>
    </div>
  </div>
  <div cdk-scrollable class="sidebar-sheet fx-flex-grow">
    <div class="sidebar-picture-container fx-layout-col fx-justify-center fx-align-center">
      <img [alt]="images[0] && (images[0].alt || 'LABEL.ALT_WORK_MAIN_PICTURE') || 'LABEL.NO_WORK_PICTURE' | translate"
           [src]="images[0]?.url || appConfig.PLACEHOLDER_CAROUSEL"
           id="sidebar-picture"/>
    </div>

    <div class="page-sidebar">
      <div class="form-container">
        <form-builder *ngIf="work"
                      [eventsOrigin]="eventsOrigin"
                      [permissionsForEdition]="permissionsForEdition"
                      formId="work_sidebar">
        </form-builder>
      </div>
      <div class="document-title fx-layout-row fx-justify-start fx-align-center">
        <span class="fx-flex-30-percent"> {{ 'LABEL.DOCUMENT' | translate }} </span>
        <restricted-text-button
          (onClick)="uploadWorkDocuments()"
          [id]="'works-upload-document-button'"
          [permissions]="[Permission.EDIT_WORK, Permission.CREATE_DOCUMENT]"
          class="btn-upload-document">
          {{ 'BUTTON.ADD_DOCUMENT' | translate }}
        </restricted-text-button>
      </div>
      <div *ngFor="let document of work?.documents" class="document-content">
        <a (click)="fileService.downloadFile(document.id)"
           *ngIf="accessManager.hasAccess(Permission.EXPORT_DOCUMENT); else noExportPermission">
          <i class="mdi mdi-file-outline xs"></i>
          <span class="file-name-label">{{ document.name }}</span>
        </a>
        <ng-template #noExportPermission>
          <span class="file-name-label">{{ document.name }}</span>
        </ng-template>
        <span (click)="deleteWorkDocument(document)"
              [permission-lock]="[Permission.EDIT_WORK, Permission.DELETE_DOCUMENT]" class="delete-document-icon">
            <i class="mdi mdi-delete xs"></i>
          </span>
      </div>
    </div>
  </div>
</div>
