import { Component, inject } from '@angular/core';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Space, SpaceInput } from '@app/core/model/entities/asset/space';
import { Document } from '@app/core/model/entities/document/document';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { SpaceModalService } from '@app/features/main/views/organization-spaces/modals/space-modal.service';
import { SpacesService } from '@app/features/main/views/organization-spaces/spaces.service';
import { AbstractSidebar } from '@app/shared/components/side-panel/abstract-sidebar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'space-sidebar',
  templateUrl: './space-sidebar.component.html',
  providers: [SpaceModalService, DocumentModalService]
})
export class SpaceSidebarComponent extends AbstractSidebar<Space> {

  public permissionsForEdition: string[] = [PermissionEnum.EDIT_SPACE];

  // Injection
  protected translate: TranslateService = inject(TranslateService);
  private spacesService = inject(SpacesService);
  private spaceModalService = inject(SpaceModalService);

  constructor() {
    super();

    this.entitiesUpdated$ = this.spacesService.spacesUpdated$;
    this.sidePanelToggle$ = this.spacesService.sidePanelToggle$
      .pipe(switchMap(spaceData => {
        return this.fieldService.getField('name', 'SPACE')
          .pipe(
            map(field => {
              this.validators = field.validators;
              return spaceData;
            })
          );
      }));
    this.afterLoadRequests.push(this.loadSpaceDocuments.bind(this));
  }

  /**
   * Loads the Space Documents.
   * @param space Space to load the Documents for.
   * @return Observable<Document[]>.
   */
  private loadSpaceDocuments(space: Space): Observable<Document[]> {
    return this.spacesService.loadSpaceDocuments(space.id)
      .pipe(
        takeUntil(this.destroy$),
        tap(documents => this.entity.documents = documents)
      );
  }

  /**
   * API call to update the Space.
   * @param data The update input.
   * @returns The updated Space.
   */
  protected override updateEntity(data: Record<string, any>): Observable<Space> {
    Space.transformPropertiesForInput(data);
    return this.spacesService.updateSpace(this.entity, data as SpaceInput)
      .pipe(
        tap(updatedSpace => {
          updatedSpace.documents = this.entity.documents;
          this.entity = updatedSpace;
        })
      );
  }

  /**
   * Close the side panel.
   */
  public override closeSidePanel(): void {
    this.spacesService.closeSpaceSidePanel();
  }

  /**
   * Navigate to the Space's related Asset's sheet's spaces tab.
   */
  public async navigateToAssetSheet(): Promise<void> {
    this.closeSidePanel();
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.SHEET,
      EntityTypeEnum.ASSET,
      this.entity.assetId
    );
    await this.spacesService.navigateToAssetSheet(this.entity.assetId);
  }

  /**
   * Open a dialog for the User to upload Documents related to the Space.
   */
  public uploadSpaceDocuments(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.UPLOAD_DIALOG,
      EntityTypeEnum.SPACE,
      this.entity.id
    );
    this.spaceModalService.openUploadSpaceDocumentsDialog(this.entity.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(documents => {
        this.entity.documents.push(...documents);
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DOCUMENT_UPLOADED'));
      });
  }

  /**
   * Open a confirmation dialog before deleting a Space's Document.
   * @param document Document to delete.
   */
  public deleteSpaceDocument(document: Document): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.DOCUMENT,
      document.id
    );
    this.spaceModalService.openDeleteSpaceDocumentDialog(document)
      .pipe(takeUntil(this.destroy$))
      .subscribe(documentIsDeleted => {
        if (documentIsDeleted) {
          this.entity.documents = this.entity.documents.filter(doc => doc.id !== document.id);
        }
      });
  }

  /**
   * Display a confirmation dialog before deleting the Space.
   */
  public deleteSpace(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.SPACE,
      this.entity.id
    );
    this.spaceModalService.openDeleteSpaceModal(this.entity, this.entity.hasChildren)
      .pipe(
        takeUntil(this.destroy$),
        filter(success => success)
      )
      .subscribe(() => this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.SPACE_DELETED')));
  }

  public saveHeader(event: { value: any }): void {
    this.spacesService.updateSpace(this.entity, {name: event.value} as SpaceInput)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: _ => {
          this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.EDIT_SAVED'));
        }
      });
  }
}
