import { Document } from '@app/core/model/entities/document/document';
import { Entity } from '@app/core/model/entities/entity';
import { Condition, ValidatorConditionOperator } from '@app/core/model/other/field-config';
import { TranslateService } from '@ngx-translate/core';
import dayjs, { Dayjs } from 'dayjs';
import { EmptyError, MonoTypeOperatorFunction, Observable, OperatorFunction, throwError, throwIfEmpty } from 'rxjs';
import { createOperatorSubscriber } from 'rxjs/internal/operators/OperatorSubscriber';
import { operate } from 'rxjs/internal/util/lift';
import { catchError } from 'rxjs/operators';

export function isNullOrEmpty(value: any): boolean {
  return (Array.isArray(value) && value.length === 0)
    || value === void 0
    || value === ''
    || value === null
    || Object.keys(value).length === 0;
}

/**
 * Returns the stored value at the given path. Options can include a custom path to use as root.
 * @param object Object to get the value from.
 * @param path List of keys that compose the path.
 * @param options Optional parameters.
 */
export function getValue(object: any, path: string[], options: any = {}): any {
  const root = options.customPath ? object[options.customPath] : object;
  return path.reduce((value: any, path) => value ? value[path] : value, root);
}

/**
 * Stores the provided value at the given path. Options can include a custom path to use as root.
 * @param object Object in which the value is to be stored.
 * @param path List of keys that compose the path.
 * @param value Value to be stored.
 */
export function setValue(object: any, path: string[], value: any): void {
  const parent = path.slice(0, path.length - 1);
  deepAssignAlongPath(object, parent, value, path.lastItem());
}


/**
 * Check if an object has a property at the given path.
 * @param object the object to check.
 * @param path the path of the property.
 * @return true if the object has the property, false otherwise
 */
export function hasProperty(object: any, path: string[]): boolean {
  return path.reduce((value: any, path) => value ? value[path] : value, object) !== void 0;
}

/**
 * Comparator between two pictures, the main picture document is always at the top.
 * @param mainPictureDocumentId The ID of the main picture document.
 * @return A compare function used as a parameter for the {@link Array.sort sort} function to sort an array of pictures
 */
export function getPictureComparator(mainPictureDocumentId: string): (pictureA: Document,
                                                                      pictureB: Document) => number {
  return (pictureA: Document, pictureB: Document): number => {
    switch (mainPictureDocumentId) {
      case pictureA.id:
        return -1;
      case pictureB.id:
        return 1;
      default:
        return 0;
    }
  };
}

/**
 * Assign the source to the target object, creating the nested key according to the given path if they do not exist,
 * avoiding overriding existing key as an Object.assign will do.
 * @param target The target Object
 * @param path the path for the assignment
 * @param source the data to assign
 * @param keyName the name of the key where to perform the assign, at the end of the path
 * @private
 */
function deepAssignAlongPath(
  target: any,
  path: string[],
  source: any,
  keyName: string
): void {
  const key = path.shift();
  if (key != void 0) {
    if (!target.hasOwnProperty(key)) {
      target[key] = {};
    }
    deepAssignAlongPath(target[key], path, source, keyName);
    return;
  }
  target[keyName] = source;
}

/**
 * Build a function that return the translated value if it exists.
 * @param translate The translation service
 */
export function translateValueBuilder(translate: TranslateService): (value: string, code: string) => string {
  return (value, code): string => {
    let key = 'VALUE.' + code?.toUpperCase() + '_' + value?.toUpperCase();
    let translateValue = translate.instant(key);
    if (translateValue !== key) return translateValue;
    //TODO Remove when all keys are prefixed by fieldCode
    key = 'VALUE.' + value?.toUpperCase();
    translateValue = translate.instant(key);
    return translateValue === key ? value : translateValue;
  };
}

/**
 * Transform the string to lower case, removing accent characters and other diacritics.
 * @param input Original string.
 * @return Transformed string.
 */
export function simplifyStringForSearch(input: string): string {
  return input.toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '');
}

export function calculateVectorLength(A, B): number {
  return Math.sqrt(Math.pow(Math.abs(B.x - A.x), 2) + Math.pow(Math.abs(B.z - A.z), 2));
}

export function enumFromStringValue<T> (enm: { [s: string]: T}, value: string): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? value as unknown as T
    : undefined;
}

export const RAD_TO_DEGREE = 180 / Math.PI;
export const DEGREE_TO_RAD = Math.PI / 180;

/**
 * Function which is basically a tap, but only for the first element emitted by the source
 * Useful for toggles once at least one element is received from the source
 * @param doOnFirst
 */
export function doOnFirst<T>(doOnFirst: ((value: T) => void)): MonoTypeOperatorFunction<T> {
  let first = true;
  return operate((source, subscriber) => {
    source.subscribe(createOperatorSubscriber(
        subscriber,
        (value) => {
          if (first) {
            doOnFirst(value);
            first = false;
          }
          subscriber.next(value);
        },
        () => subscriber.complete(),
        err => subscriber.error(err)
      )
    );
  });
}


/**
 * Switch to the fallback observable if the source is empty.
 * @param fallback
 * @return Observable
 */
export function switchIfEmpty<T, U>(fallback: Observable<U>): OperatorFunction<T, T | U> {
  return (source: Observable<T>): Observable<T | U> => {
    return source.pipe(
      throwIfEmpty(),
      catchError((err) => err instanceof EmptyError ? fallback : throwError(() => err))
    );
  };
}

/**
 * Wait until the source Observable completes then emit the value.
 * @param value Value to emit once the source completes.
 * @return OperatorFunction.
 */
export function thenReturn<T>(value: T): OperatorFunction<unknown, T> {
  return operate((source, subscriber) => {
    source.subscribe(createOperatorSubscriber(subscriber, () => {}, () => {
      subscriber.next(value);
      subscriber.complete();
    }));
  });
}

/**
 * Check if a number is close to zero.
 * @param num number to check.
 * @return boolean true if the numbers is close to zero, otherwise false.
 */
export function isNumberCloseToZero(num: number): boolean {
  // FIXME Respect the number of significant digits instead of using this method.
  return num >= 0 && num <= 0.009;
}

/**
 * Evaluates if a given condition is true for an entity
 * @param entity entity in which properties might be looked up
 * @param condition Condition to check
 * @return True if condition is met, false otherwise
 */
export function validateCondition(entity: Entity, condition: Condition) {
  const path = condition.field?.split('.');
  const valueFromEntity = path ? getValue(entity, path) : null;
  // Test the conditional validator condition
  switch (condition.operator) {
    case ValidatorConditionOperator.EMPTY:
      return isNullOrEmpty(valueFromEntity?.toString());
    case ValidatorConditionOperator.EXISTS:
      return !isNullOrEmpty(valueFromEntity?.toString());
    case ValidatorConditionOperator.NOT_CONTAINS:
      return !condition.value.includes(valueFromEntity?.toString());
    case ValidatorConditionOperator.CONTAINS:
      return condition.value.includes(valueFromEntity?.toString());
    case ValidatorConditionOperator.EQUALS:
      return condition.value === valueFromEntity;
    case ValidatorConditionOperator.NOT_EQUALS:
      return condition.value !== valueFromEntity;
    case ValidatorConditionOperator.GREATER_THAN:
      return valueFromEntity > condition.value;
    case ValidatorConditionOperator.LESS_THAN:
      return valueFromEntity < condition.value;
    case ValidatorConditionOperator.NEVER:
      return false;
    case ValidatorConditionOperator.ALWAYS:
      return true;
    default:
      return false;
  }
}

/**
 * Take an object containing styles that should be applied depending on conditions,
 * and return an object containing styles with their corresponding value
 * @param stylesConditions An object containing conditional css styles to apply
 * @param entity Entity of the field being rendered
 * @return An object containing css styles to apply
 */
export function customCssRenderer(
  stylesConditions: StylesConditions,
  entity: Entity
): { [cssProperty: string]: string } {
  if (!Object.entries(stylesConditions)?.length) return {};
  return Object.entries(stylesConditions).reduce((cssStyles, [cssProperty, cssValues]) => {
    // Evaluates all conditions to apply the css value
    cssValues.forEach(({ cssValue, conditions }) => {
      if (conditions.every((condition) => validateCondition(entity, condition))) {
        cssStyles[cssProperty] = cssValue;
      }
    });
    return cssStyles;
  }, {});
}

export type StylesConditions = { [key: string]: { cssValue: string, conditions: Condition[] }[] };

/**
 * Evenly balance items in arrays
 * @param items the items to balance
 * @param maxItemsPerArray the maximum number of item per array
 * @return the arrays containing the items
 */
export function balanceItemsInArrays<Type>(items: Type[], maxItemsPerArray: number): Type[][] {
  const numberOfLines = Math.ceil(items.length / maxItemsPerArray);
  const numberOfItemsPerArray = Math.ceil(items.length / numberOfLines);

  // balance items in arrays
  const arrays = Array.from({ length: numberOfLines }, () => []);
  items.forEach((item, index) => {
    const arrayIndex = Math.floor(index / numberOfItemsPerArray);
    arrays[arrayIndex].push(item);
  });
  return arrays;
}

/**
 * Parse a string to a Day.js object.
 * @param value String representation of a date.
 * @return Parsed Day.js object or null if the input value could not be parsed as a valid Day.js object.
 */
export function dateParser(value: string): Dayjs | null {
  const date = dayjs(value);
  return date.isValid() ? date : null;
}

/**
 * Remove the __typename property from a GraphQl object recursively.
 * Use as necessary, some queries rely on __typename.
 * Extracted from https://stackoverflow.com/a/73326109
 */
export function removeTypenameProperty(item): any {
  if (!item) return;

  const recurse = (source, obj) => {
    if (!source) return;

    if (Array.isArray(source)) {
      for (let i = 0; i < source.length; i++) {
        const item = source[i];
        if (item !== undefined && item !== null) {
          source[i] = recurse(item, item);
        }
      }
      return obj;
    } else if (typeof source === 'object') {
      for (const key in source) {
        if (key === '__typename') continue;
        const property = source[key];
        if (Array.isArray(property)) {
          obj[key] = recurse(property, property);
        } else if (!!property && typeof property === 'object') {
          const {__typename, ...rest} = property;
          obj[key] = recurse(rest, rest);
        } else {
          obj[key] = property;
        }
      }
      const {__typename, ...rest} = obj;

      return rest;
    } else {
      return obj;
    }
  };

  return recurse(structuredClone(item), {});
}
