import { Injectable } from '@angular/core';
import { AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';
import { LeaseStatusEnum } from '@app/core/enums/asset-occupant/lease-status-enum';
import { ContractStateEnum } from '@app/core/enums/contract/contract-state.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Asset } from '@app/core/model/entities/asset/asset';
import { Space } from '@app/core/model/entities/asset/space';
import { Document } from '@app/core/model/entities/document/document';
import { Entity } from '@app/core/model/entities/entity';
import { Condition, FieldConfig, isValidatorFieldDependant, ValidatorType } from '@app/core/model/other/field-config';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { EquipmentsService } from '@app/features/main/views/equipments/equipments.service';
import { SpacesService } from '@app/features/main/views/organization-spaces/spaces.service';
import { ProjectsService } from '@app/features/main/views/projects/projects.service';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { getValue, validateCondition } from '@app/shared/extra/utils';
import { ExtraValidators } from '@app/shared/validators/extra-validators.module';
import { GeneralService } from '@services/general.service';
import { concatMap, defaultIfEmpty, EMPTY, every, from, Observable } from 'rxjs';

@Injectable()
export class ValidationService {

  constructor(private formStateService: FormStateService,
              private generalService: GeneralService,
              private equipmentsService: EquipmentsService,
              private projectsService: ProjectsService,
              private spacesService: SpacesService) {
  }


  /**
   * Get the validator corresponding to the validator's code name
   * @param validator the validator config
   * @param entity available if information should be extracted from the entity to configure the validator
   * @param fieldCode the validation target field's code
   */
  public getValidator(validator: FieldValidator, entity?: Entity, fieldCode?: string): ValidatorFn {
    switch (validator.type) {
      case 'MAX_LENGTH':
        return Validators.maxLength(validator.definition);
      case 'MIN_VALUE':
        return ExtraValidators.gte(validator.definition);
      case 'MAX_VALUE':
        return ExtraValidators.lte(validator.definition);
      case 'INTEGER':
        return ExtraValidators.integer;
      case 'MAX_NOW':
        return ExtraValidators.maxNow;
      case 'PERCENT':
        return ExtraValidators.percent;
      case 'YEAR':
        return ExtraValidators.year;
      case 'DATE':
        return ExtraValidators.dateISO;
      case 'DECIMAL':
        return ExtraValidators.decimal;
      case 'MISSING':
        return ExtraValidators.notInArray(validator.definition);
      case 'AFTER_DATE':
        return ExtraValidators.afterDate(validator.definition);
      case 'BEFORE_DATE':
        return ExtraValidators.beforeDate(validator.definition);
      case 'REQUIRED':
        return Validators.required;
      case 'END_DATE_IS_BEFORE_TODAY':
        if (entity.entityType === EntityTypeEnum.LEASE) {
          return ExtraValidators.endDateIsBeforeToday(
            () => this.formStateService.getPath(['leaseDates', 'value', 'endDate']),
            LeaseStatusEnum.LEASE_ACTIVE
          );
        } else if (entity.entityType === EntityTypeEnum.CONTRACT) {
          return ExtraValidators.endDateIsBeforeToday(
            () => this.formStateService.getPath(['contractDates', 'value', 'endDate']),
            ContractStateEnum.CONTRACT_ACTIVE
          );
        } else {
          // Type of entity is not handled for this validator
          return Validators.nullValidator;
        }
      case 'END_DATE_IS_AFTER_TODAY':
        if (entity.entityType === EntityTypeEnum.LEASE) {
          return ExtraValidators.endDateIsAfterToday(
            () => this.formStateService.getPath(['leaseDates', 'value', 'endDate']),
            LeaseStatusEnum.LEASE_EXPIRED
          );
        } else if (entity.entityType === EntityTypeEnum.CONTRACT) {
          return ExtraValidators.endDateIsAfterToday(
            () => this.formStateService.getPath(['contractDates', 'value', 'endDate']),
            ContractStateEnum.CONTRACT_EXPIRED
          );
        } else {
          // Type of entity is not handled for this validator
          return Validators.nullValidator;
        }
      case 'END_DATE_IS_UNDEFINED':
        if (entity.entityType === EntityTypeEnum.LEASE) {
          return ExtraValidators.endDateIsUndefined(
            () => this.formStateService.getPath(['leaseDates', 'value', 'endDate']),
            LeaseStatusEnum.LEASE_EXPIRED
          );
        } else if (entity.entityType === EntityTypeEnum.CONTRACT) {
          return ExtraValidators.endDateIsUndefined(
            () => this.formStateService.getPath(['contractDates', 'value', 'endDate']),
            ContractStateEnum.CONTRACT_EXPIRED
          );
        } else {
          // Type of entity is not handled for this validator
          return Validators.nullValidator;
        }
      case 'REALISATION_DATE_IS_BEFORE_TODAY':
        return ExtraValidators.realisationDateIsBeforeToday(
          () => this.formStateService.getPath(['realisationDate', 'value', 'realisationDate']),
          () => this.formStateService.getPath(['periodicity', 'value', 'periodicity'])
        );
      case 'REALISATION_DATE_IS_AFTER_TODAY':
        return ExtraValidators.realisationDateIsAfterToday(
          () => this.formStateService.getPath(['realisationDate', 'value', 'realisationDate']),
          () => this.formStateService.getPath(['periodicity', 'value', 'periodicity'])
        );
      case 'RENEW_DATE_IS_AFTER_TODAY':
        return ExtraValidators.renewDateIsAfterToday(
          () => this.formStateService.getPath(['realisationDate', 'value', 'realisationDate']),
          () => this.formStateService.getPath(['periodicity', 'value', 'periodicity'])
        );
      case 'RENEW_DATE_IS_UNDEFINED':
        return ExtraValidators.renewDateIsUndefined(
          () => this.formStateService.getPath(['realisationDate', 'value', 'realisationDate']),
          () => this.formStateService.getPath(['periodicity', 'value', 'periodicity'])
        );
      case 'INCOHERENT_SPACES':
        return ExtraValidators.checkSpaces;
      case 'GTE_TOTAL_EXPENSES':
      case 'LTE_TOTAL_COSTS':
        return ExtraValidators.checkBudgetBalance(this.formStateService);
      case 'MAX_LENGTH_FILE_NAME' :
        return ExtraValidators.maxLengthFileName(
          validator.definition,
          entity instanceof Document ? entity?.getExtension() : ''
        );
      case 'REGEX':
        return ExtraValidators.regex(validator.definition);
      case 'DISTINCT':
      case 'AFTER_OTHER_DATE': // This validator is supposed to have been replaced by an afterDate validator that should be updated as the compare value changes
      case 'BEFORE_OTHER_DATE': // This validator is supposed to have been replaced by an beforeDate validator that should be updated as the compare value changes
      case 'READ_ONLY':
      case 'GTE_FIELD': // This validator is supposed to have been replaced by a gte validator that should be updated as the compare value changes
      case 'LTE_FIELD': // This validator is supposed to have been replaced by a lte validator that should be updated as the compare value changes
      case 'REQUIRED_TRUE':
      case 'EQUAL_TO':
      case 'NOT_AVAILABLE':
      default:
        return Validators.nullValidator;
    }
  }

  /**
   * Get the validator corresponding to the validator's code name
   * @param validator the validator configuration
   * @param entity available if information should be extracted from the entity to configure the validator
   * @param fieldCode the validation target field's code
   */
  public getAsyncValidator(validator: FieldValidator,
                           entity?: Entity,
                           fieldCode?: string): AsyncValidatorFn {
    switch (validator.type) {
      case 'UNIQUE':
        return ExtraValidators.isValueTaken(
          this.generalService,
          EntityTypeEnum[entity.entityType],
          fieldCode,
          entity.id,
          { [validator.definition]: getValue(entity, [validator.definition]) }
        );
      case 'GTE_SPACE_CHILDREN_PROPERTY':
        return ExtraValidators.gteSpaceChildrenProperty(this.spacesService, fieldCode, entity as Space);
      case 'LTE_SPACE_PARENT_PROPERTY':
        return ExtraValidators.lteSpaceParentProperty(this.spacesService, fieldCode, entity as Space);
      case 'ASSET_VALUE_GTE_SPACE_CHILDREN_PROPERTY':
        return ExtraValidators.assetValueGteSpaceChildrenProperty(
          this.spacesService,
          validator.definition,
          entity as Asset
        );
      case 'IS_SPACE_MOVE_ALLOWED':
        return ExtraValidators.isSpaceMoveAllowed(this.spacesService, entity as Space);
      default:
        return null;
    }
  }

  /**
   * Get the async precondition validator corresponding to the validator's code name.
   * @param validator validator config.
   * @param entity The entity related to the field.
   * @return Observable emitting true or false by the validator, empty otherwise.
   * @protected
   */
  protected getAsyncPreconditionValidator(validator: FieldValidator, entity: Entity): Observable<boolean> {
    switch (validator.type) {
      case 'PROJECT_HAS_INACCESSIBLE_ASSETS':
        return this.projectsService.projectHasInaccessibleAssets(entity.id);
      default:
        return EMPTY;
    }
  }

  /**
   * Used to validate that a field can be edited
   * @param fieldConfig Configuration of the field to check
   * @param entity Used to check editable conditions depending on entity
   * @return True if the field can be editable, false if it is read only
   */
  public fieldIsEditable(fieldConfig: FieldConfig, entity?: Entity): boolean {
    // No config found, can not edit.
    if (!fieldConfig) return false;
    // Field is computed, can not edit unless a condition to edit is specified.
    if (fieldConfig.computed && fieldConfig.conditionsToEdit.length === 0) return false;
    // Evaluate conditions to edit
    return this.validateConditions(entity, fieldConfig.conditionsToEdit);
  }

  /**
   * Iterate over conditions to validate them using the entity being edited
   * @param entity entity in which properties might be looked up
   * @param conditions list of conditions to check
   * @return True if conditions are met, false otherwise
   */
  public validateConditions(entity: Entity, conditions: Condition[] = []): boolean {
    // If no conditions, there is nothing to validate
    if (conditions.length === 0) return true;
    return conditions.every((condition) => {
      return validateCondition(entity, condition);
    });
  }

  /**
   * Check if the field has an async validator type.
   * If true, checks the async validator condition and return false if the field
   * matches the validator condition (meaning that the field must be read only).
   * In every other case, return true (meaning that the field can be editable).
   * @param validators The field validator list.
   * @param entity The entity related to the field.
   * @return Observable emitting true if the field is editable, false otherwise.
   */
  public getAsyncPreconditionsToEdit(validators: FieldValidator[], entity: Entity): Observable<boolean> {
    return from(validators)
      .pipe(
        // Check the async validator condition.
        concatMap((validator) => this.getAsyncPreconditionValidator(validator, entity)),
        // Check if all the results are false.
        every(result => !result),
        // True by default (meaning that the field can be editable).
        defaultIfEmpty(true)
      );
  }

  /**
   * Get the type which replace the validator type of a validator which depends on another field in the entity.
   * @param type Type of the validator which should be converted.
   * @return ValidatorType Type the validator should be converted into, or null if there is no need to convert it.
   */
  public getFieldDependantValidatorType(type: ValidatorType): ValidatorType {
    switch (type) {
      case 'AFTER_OTHER_DATE':
        return 'AFTER_DATE';
      case 'BEFORE_OTHER_DATE':
        return 'BEFORE_DATE';
      case 'LTE_FIELD':
        return 'MAX_VALUE';
      case 'GTE_FIELD':
        return 'MIN_VALUE';
      default:
        return null;
    }
  }

  /**
   * Check if a validator depends on another field of the entity and, if so, convert it into the corresponding validator.
   * @param validatorToConvert Validator depending on another field of the entity.
   * @param definition Value to set as the definition of the converted validator.
   * @return FieldValidator Return the corresponding validator, or the validator itself if no replacement was needed.
   */
  public convertFieldDependantValidator(validatorToConvert: FieldValidator,
                                        definition: any): FieldValidator {
    return isValidatorFieldDependant(validatorToConvert.type) ? <FieldValidator>{
      type: this.getFieldDependantValidatorType(validatorToConvert.type),
      definition: definition,
      ref: validatorToConvert.definitionPath.lastItem()
    } : validatorToConvert;
  }
}
