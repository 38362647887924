import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { RetoolApp, RetoolAppInput } from '@app/core/model/entities/retool/retool-app';
import { environment } from '@env/environment';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { ErrorManager } from '@services/managers/error.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class RetoolAppService implements Resolve<SafeResourceUrl> {
  private addRetoolAppSubject = new Subject<RetoolApp>();
  private updateRetoolAppSubject = new Subject<RetoolApp>();
  private deleteRetoolAppSubject = new Subject<RetoolApp>();

  private retoolAppGraphQlFragment = gql`
    fragment RetoolAppInfo on RetoolApp {
      id
      name
      color
      organizationId
      retoolId
      groupId
    }
  `;

  constructor(private generalService: GeneralService,
              private errorManager: ErrorManager,
              private appManager: AppManager,
              private sanitizer: DomSanitizer) {}

  /**
   * Observable that emits the list of all the current Organization's RetoolApp
   * @return RetoolApp[]
   */
  public get retoolApps$(): Observable<RetoolApp[]> {
    const query = gql`
      query RetoolApps($organizationId: String!) {
        retoolApps(organizationId: $organizationId) {
          ...RetoolAppInfo
        }
      }
      ${this.retoolAppGraphQlFragment}
    `;
    const variables = {organizationId: this.appManager.currentOrganization.id};
    return this.generalService.get(query, variables)
      .pipe(map(response => plainToInstance(RetoolApp, response.data['retoolApps'] as RetoolApp[])));
  }

  /**
   * Observable that emits a RetoolApp after it has been created.
   */
  public get retoolAppAdded$(): Observable<RetoolApp> {
    return this.addRetoolAppSubject.asObservable();
  }

  /**
   * Observable that emits a RetoolApp after it has been updated.
   */
  public get retoolAppUpdated$(): Observable<RetoolApp> {
    return this.updateRetoolAppSubject.asObservable();
  }

  /**
   * Observable that emits a RetoolApp after it has been deleted.
   */
  public get retoolAppDeleted$(): Observable<RetoolApp> {
    return this.deleteRetoolAppSubject.asObservable();
  }

  /**
   * Fetch the url of an app based on ID contained in the activated route's path.
   * @param route Activated route.
   * @return SafeResourceUrl
   */
  public resolve(route: ActivatedRouteSnapshot): Observable<SafeResourceUrl> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const uri = environment.backend.baseUrl + environment.backend.retool.endpoint + route.paramMap.get('id');

    return this.generalService.httpGetAsJson(uri, headers)
      .pipe(
        map(result => this.sanitizer.bypassSecurityTrustResourceUrl(result?.['embedUrl'])),
        catchError(({error, status, message}) => {
          // handle error
          this.errorManager.handleNetworkError({error, status, message});
          throw error;
        })
      );
  }

  /**
   * Call the API to create a RetoolApp and start processing the related data.
   * @param appInput Data for creating the RetoolApp.
   * @return Observable that emits the created RetoolApp.
   */
  public createApp(appInput: RetoolAppInput): Observable<RetoolApp> {
    const mutation = gql`
      mutation CreateRetoolApp($organizationId: String!, $appInput: RetoolAppInput!) {
        createRetoolApp(organizationId: $organizationId, appInput: $appInput) {
          ...RetoolAppInfo
        }
      }
      ${this.retoolAppGraphQlFragment}
    `;
    const variables = {organizationId: this.appManager.currentOrganization.id, appInput};
    return this.generalService.set(mutation, variables)
      .pipe(
        map(response => response.data['createRetoolApp'] as RetoolApp),
        tap(app => this.addRetoolAppSubject.next(app))
      );
  }

  /**
   * Call the API to update a RetoolApp.
   * @param appId ID of the app in database.
   * @param appInput Data for updating the RetoolApp.
   * @return Observable that emits the updated RetoolApp.
   */
  public editApp(appId: number, appInput: RetoolAppInput): Observable<RetoolApp> {
    const mutation = gql`
      mutation UpdateRetoolApp($appId: Int!, $appInput: RetoolAppInput!) {
        updateRetoolApp(appId: $appId, appInput: $appInput) {
          ...RetoolAppInfo
        }
      }
      ${this.retoolAppGraphQlFragment}
    `;
    const variables = {appId, appInput};
    return this.generalService.set(mutation, variables)
      .pipe(
        map(response => response.data['updateRetoolApp'] as RetoolApp),
        tap(app => this.updateRetoolAppSubject.next(app))
      );
  }

  /**
   * Call the API to delete a RetoolApp and stop any ongoing data processing.
   * @param retoolApp RetoolApp to delete.
   * @return Observable that emits a boolean indicating whether the RetoolApp was successfully deleted.
   */
  public deleteRetoolApp(retoolApp: RetoolApp): Observable<boolean> {
    const mutation = gql`
      mutation DeleteRetoolApp($appId: Int!) {
        deleteRetoolApp(appId: $appId)
      }
    `;
    const variables = {appId: retoolApp.id};
    return this.generalService.set(mutation, variables)
      .pipe(
        map(response => response.data['deleteRetoolApp'] as boolean),
        tap(deleted => deleted && this.deleteRetoolAppSubject.next(retoolApp))
      );
  }
}
