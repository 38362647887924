import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig
} from '@app/core/model/other/field-config';
import { FieldValidator } from '@app/core/model/other/field-validator';
import {
  NumberFieldBuilderComponent
} from '@app/shared/components/fields/number-field-builder/number-field-builder.component';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { format } from '@app/shared/extra/decimal-format';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'percent-field-builder',
  templateUrl: './percent-field-builder.component.html',
  styleUrls: ['../number-field-builder.component.scss']
})
export class PercentFieldBuilderComponent extends NumberFieldBuilderComponent implements OnInit, AfterViewInit {

  public Permission = PermissionEnum;

  @ViewChild('inputField') public declare inputField: ElementRef;

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldConfig,
      preconditionsForEdition,
      permissionsForEdition,
      appManager,
      appConfig,
      accessManager,
      media,
      translate,
      validationService,
      singleEditService,
      analyticsService
    );
  }

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({
      field: new UntypedFormControl(
        (this.fieldInitValue ?? 0) * 100,
        this.computeValidators()
      )
    });

    this.form.updateValueAndValidity({emitEvent: false});
    // Initialise the field in the registry
    this.setFieldValue(this.fieldInitValue);
    this.setFieldInitialValue(this.fieldInitValue);
    this.getNextState();
  }

  public ngAfterViewInit(): void {
    this.setupHooks();

    this.form.get('field').valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(50),
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.setFieldValue(value ? format(value / 100) : value);

      // When other field value changes, tell anything hooked to the valuechange to update accordingly
      if (this.formStateService.getPath(['gteField', this.fieldConfig.fieldCode])) {
        this.formStateService.getPath(['gteField', this.fieldConfig.fieldCode]).next(value);
      }
      if (this.formStateService.getPath(['lteField', this.fieldConfig.fieldCode])) {
        this.formStateService.getPath(['lteField', this.fieldConfig.fieldCode]).next(value);
      }
      if (this.formStateService.getPath(['computed'])) {
        this.formStateService.getPath(['computed']).next(value);
      }
    });
  }

  /**
   * Manually add fieldtype-specific validators. Replace lte backend validator to a frontend one.
   * @returns {ValidatorFn}
   */
  protected computeValidators(): ValidatorFn {
    const percentValidators = this.fieldConfig.field.validators.filter(validator => validator.type !== 'MAX_VALUE');
    percentValidators.push(<FieldValidator>{type: 'MAX_VALUE', definition: '100'});
    this.fieldConfig.field.validators = percentValidators;
    return super.computeValidators();
  }
}
