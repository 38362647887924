import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControlStatus, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { MatChipGrid } from '@angular/material/chips';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { FieldTypeEnum } from '@app/core/enums/field-type-enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig
} from '@app/core/model/other/field-config';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { AbstractFieldBuilder } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { simplifyStringForSearch } from '@app/shared/extra/utils';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { Observable } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';


@Component({
  selector: 'autocomplete-field-builder',
  templateUrl: './autocomplete-field-builder.component.html',
  styleUrls: ['./autocomplete-field-builder.component.scss']
})
export class AutocompleteFieldBuilderComponent extends AbstractFieldBuilder implements OnInit, AfterViewInit {

  public Permission = PermissionEnum;
  public filteredOptions: Observable<string[]>;
  @ViewChild('chipList') public chipList: MatChipGrid;
  @ViewChild('inputField') public inputField: ElementRef;

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldConfig,
      preconditionsForEdition,
      permissionsForEdition,
      appManager,
      appConfig,
      accessManager,
      media,
      translate,
      validationService,
      singleEditService,
      analyticsService
    );
  }

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({
      field: new UntypedFormControl(
        this.fieldInitValue,
        this.computeValidators()
      )
    });
    // Propagate invalid status to the mat-chip-list
    this.form.get('field').statusChanges.subscribe(
      (status: FormControlStatus) => {
        if (this.chipList !== void 0) {
          this.chipList.errorState = status === 'INVALID';
        }
      });

    // Initialise the field in the registry
    this.setFieldValue(this.fieldInitValue);
    this.setFieldInitialValue(this.fieldInitValue);
    this.getNextState();

    // Listen for value changes to update filtered values
    this.filteredOptions = this.form.get('field').valueChanges
      .pipe(
        startWith(''),
        filter(value => value !== void 0),
        map(value => {
          return this.fieldConfig.field.fieldValues?.filter(option =>
            simplifyStringForSearch(option).includes(simplifyStringForSearch(value))
          );
        })
      );

    // Refresh values after save
    this.formStateService.saved$.pipe(
      takeUntil(this.destroy$),
      filter(({code}) => {
        const fieldValue = this.form.get('field').value as string;
        return !!fieldValue
          && code === this.fieldConfig.fieldCode
          && !this.fieldConfig.field.fieldValues.includes(fieldValue);
      }),
      tap(() => this.fieldConfig.field.fieldValues.push(this.form.get('field').value)),
      // Resubscribe to the saved observable if an error occurred.
      catchError(() => this.formStateService.saved$)
    ).subscribe();
  }

  public ngAfterViewInit(): void {
    this.setupHooks();

    this.form.get('field').valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(50),
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.setFieldValue(value);
    });
  }

  public cancel(): void {
    this.form.get('field').setValue(this.getFieldInitialValue());
    super.cancel();
  }

  protected computeValidators(): ValidatorFn {
    if (this.fieldConfig.field.fieldType === FieldTypeEnum.TEXT_AUTOCOMPLETE) {
      this.fieldConfig.field?.validators.push(<FieldValidator>{
        type: 'MISSING',
        definition: this.fieldConfig.field.fieldValues
      });
    }
    return super.computeValidators();
  }

  /**
   * Set focus on the element
   */
  public focus(): void {
    if (this.focusable) {
      setTimeout(() => this.inputField.nativeElement.focus());
    }
  }
}
