import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteOrigin, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { AppConfig } from '@app/core/app.config';
import { simplifyStringForSearch } from '@app/shared/extra/utils';
import { Observable, of, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mat-select-autocomplete[dataSource]',
  templateUrl: './mat-select-autocomplete.component.html',
  styleUrls: ['./mat-select-autocomplete.component.scss'],
  providers: [
    {provide: MatFormFieldControl, useExisting: MatSelectAutocompleteComponent}
  ]
})
export class MatSelectAutocompleteComponent<T> implements MatFormFieldControl<T>, ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {
  private static nextId = 0;

  // Fields from MatFormFieldControl
  public readonly stateChanges = new Subject<void>();
  @HostBinding('id')
  public readonly id = `mat-select-autocomplete-${MatSelectAutocompleteComponent.nextId++}`;
  public readonly controlType = 'mat-select-autocomplete';
  public autocompleteOrigin: MatAutocompleteOrigin;

  // Component inputs
  @Input() public placeholder: string;
  @Input('aria-describedby') public userAriaDescribedBy: string;

  /**
   * Source of data for autocompletion.
   * If the returned items list might change, use an Observable and not an array or else data will not be updated in the mat-select-autocomplete component.
   * Returned items can be strings to be displayed in the list, or objects. If objects are used, valueFormatter callback must be set and return a
   * string representation of an option.
   */
  @Input('dataSource') public dataSourceInput$: Observable<T[]> | T[];

  /**
   * Convert an autocomplete option to its string representation for display and comparison purposes.
   * @param option Autocomplete option.
   * @return String representation of the option.
   */
  @Input() public displayWith = (option?: T): string => String(option);

  @Input() public inputId: string;

  /**
   * Format a value that is possibly null, undefined or empty into a displayable string.
   */
  public valueFormatter = this.getDisplayableValue.bind(this);

  @Output('optionSelected') public onOptionSelected = new EventEmitter<T>();

  // Autocompletion utils
  public filteredOptions: Observable<T[]>;
  public control: UntypedFormControl;

  // Event listeners
  public onChange = (_: T): void => {
    // Do nothing
  };
  public onTouched = (): void => {
    // Do nothing
  };

  // View elements
  @ViewChild('input') protected input: ElementRef;
  @ViewChild('trigger') private trigger: MatAutocompleteTrigger;
  @ViewChild('container') public container: ElementRef;

  // Private attributes
  private _value?: T = undefined;
  private _focused = false;
  private _required = false;
  private _disabled = false;
  public _touched = false;

  private observer: MutationObserver;
  protected destroy$ = new Subject<void>();

  /**
   * Init local form control and set valueAccessor.
   * @param fb FormBuilder for creating the local control.
   * @param ngControl NgControl for ControlValueAccessor interface.
   * @param formField FormField containing the MatSelectAutocomplete component.
   * @param focusMonitor FocusMonitor for handling focus within the component.
   * @param elementRef Component's ElementRef.
   * @param appConfig Application config.
   */
  constructor(public fb: UntypedFormBuilder,
              @Optional() @Self() public ngControl: NgControl,
              @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField,
              private focusMonitor: FocusMonitor,
              protected elementRef: ElementRef<HTMLElement>,
              private appConfig: AppConfig) {
    this.control = fb.control('');
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  /**
   * The control's value.
   */
  @Input()
  public get value(): T | null {
    return this._value;
  }

  /**
   * Update the control's value and display it in the form field.
   * @param value New value.
   */
  public set value(value: T | null) {
    this._value = value;
    this.control.setValue(value);
    this.stateChanges.next();
  }

  /**
   * Whether the control has focus.
   * @return True if the control has the focus, false otherwise.
   */
  public get focused(): boolean {
    return this._focused;
  }

  /**
   * Whether the control is empty. The field is considered filled as the user selects an option in the list.
   * @return True if the field's value is undefined, false if the field has its value set.
   */
  public get empty(): boolean {
    return !this._value;
  }

  /**
   * Whether the control's label should float instead of being used as placeholder. The label should float when the user
   * is typing or if the field has its value set.
   * @return True if the field has focus or is not empty, false otherwise.
   */
  @HostBinding('class.floating')
  public get shouldLabelFloat(): boolean {
    return this.focused || this.control.value;
  }

  /**
   * Whether the form control is required.
   * @return True if the field is required, false otherwise.
   */
  @Input()
  public get required(): boolean {
    return this._required;
  }

  /**
   * Set the control's required attribute.
   * @param value Whether the control is required.
   */
  public set required(value: any) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  /**
   * Whether the control is disabled.
   * @return True if disabled, false otherwise.
   */
  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }

  /**
   * Set the control's disabled attribute.
   * @param value Whether the control is disabled.
   */
  public set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.control.disable() : this.control.enable();
    this.stateChanges.next();
  }

  /**
   * Whether the control is in an error state.
   * @return True if the control has been touched and is in an invalid state, false otherwise.
   */
  public get errorState(): boolean {
    return this._touched && (this.control.invalid || this.required && this.empty);
  }

  /**
   * Whether the control has been touched.
   * @return True if the control has been touched, false otherwise.
   */
  public get touched(): boolean {
    return this._touched;
  }

  /**
   * Sets the list of element IDs that currently describe this control.
   * @param ids Elements' IDs.
   */
  public setDescribedByIds(ids: string[]): void {
    const controlElement = this.elementRef.nativeElement
      .querySelector('.mat-select-autocomplete-container');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  /**
   * Data source for the autocompletion list. If the source is an array, it will be cast as an Observable.
   * @return {Observable<T[]>} Data source for autocompletion as an Observable.
   */
  public get dataSource$(): Observable<T[]> {
    return this.dataSourceInput$ instanceof Observable ? this.dataSourceInput$ : of(this.dataSourceInput$);
  }

  /**
   * Set focus on input and open autocomplete panel whenever the user clicks on the control's container.
   * @param event Event raised on user's click on the control's contained.
   */
  public onContainerClick(event: MouseEvent): void {
    event.stopPropagation();
    this.trigger.openPanel();
    this.focusMonitor.focusVia(this.input, 'program');
  }

  /**
   * Writes a new value to the control.
   * @param obj New value.
   */
  public writeValue(obj: any): void {
    this.value = obj;
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI.
   * @param fn Callback to register.
   */
  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur.
   * @param fn Callback to register.
   */
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Function that is called by the forms API when the control status changes to or from 'DISABLED'. Depending on the
   * status, it enables or disables the appropriate DOM element.
   * @param isDisabled The disabled status to set on the element.
   */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Fetch data and filter as the user types in the text field.
   */
  public ngOnInit(): void {
    // Set filteredOptions for the autocompletion list
    this.filteredOptions = this.dataSource$.pipe(
      takeUntil(this.destroy$),
      filter(() => !this.disabled),
      switchMap(data => this.control.valueChanges.pipe(
        startWith(''),
        map((value: string | T | null): string => {
          return typeof value === 'string' ? value : this.displayWith(value) || '';
        }),
        map((stringValue: string): T[] => {
          const items = data?.filter(element => {
            return simplifyStringForSearch(this.displayWith(element))
              .includes(simplifyStringForSearch(stringValue));
          }) || [];
          return [...items];
        })
      ))
    );
  }

  /**
   * Stop listening to MutationObserver, data source, typing and focus events before the component being destroyed.
   */
  public ngOnDestroy(): void {
    this.observer?.disconnect();
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Set focus and notify state change whenever the control takes focus.
   */
  public onFocusIn(): void {
    if (!this.focused) {
      this._focused = true;
      this.focusMonitor.focusVia(this.input, 'program');
      this.stateChanges.next();
    }
  }

  /**
   * Set the control as touched, update focus state and notify state change whenever the control loses focus.
   */
  public onFocusOut(): void {
      this._touched = true;
      this._focused = false;
      this.onTouched();
      this.stateChanges.next();
  }

  /**
   * Reset the control's value after the user starts typing, if an option has previously been selected.
   */
  public onInput(): void {
    if (!this.empty) {
      this.updateValue(null);
    }
  }

  /**
   * Update the control's form based on the selected option.
   * @param element Element the user has selected in the autocomplete list.
   */
  public optionSelected(element: T | null): void {
    this.updateValue(element);
    if (element === null) {
      this.control.setValue(null);
    }
    this.onOptionSelected.emit(element);
  }

  /**
   * Set the control's value then call the onChange callback with the new value.
   * @param value New value.
   */
  public updateValue(value: T | null): void {
    this._value = value;
    this.stateChanges.next();
    this.onChange(this.value);
  }

  /**
   * Format a value using the provided external formatter. Returns a string placeholder in case the returned data is
   * null or undefined.
   * @param value Value to format.
   * @return String representation of the value, or string placeholder representing a null value.
   * @private
   */
  private getDisplayableValue(value?: T): string {
    return this.displayWith(value) || this.appConfig.EMPTY_FIELD_VALUE;
  }

  /**
   * Triggered when the visibility of the input changes. If the element is not visible,
   * it closes the panel. If the element is visible, it opens the panel and updates the position.
   * @param isVisible A boolean indicating whether the element is visible or not.
   */
  public onVisibilityChange(isVisible: boolean): void {
    if (!isVisible) {
      this.trigger?.closePanel();
    } else {
      this.trigger?.openPanel();
      this.trigger?.updatePosition();
    }
  }

  /**
   * Lifecycle hook that is called after Angular has fully initialized a component's view.
   * It assigns a new Autocomplete Origin for the panel to the parent of the input that encompasses the entire field.
   */
  public ngAfterViewInit(): void {
    this.autocompleteOrigin = new MatAutocompleteOrigin(
      new ElementRef(this.container.nativeElement.closest('.mat-mdc-form-field-flex'))
    );
  }
}
