import { Condition, isValidatorFieldDependant, ValidatorType } from '@app/core/model/other/field-config';

export class FieldValidator {
  public type: ValidatorType;
  public definition?: any;
  public conditionsList?: Condition[];
  public ref?: string;
  public customError?: string;

  constructor(type: ValidatorType, definition?: any) {
    this.type = type;
    this.definition = definition;
  }

  /**
   * return the definition path as an Array of string
   */
  public get definitionPath(): string[] {
    if (!isValidatorFieldDependant(this.type)) return null;
    return this.definition.split('.');
  }
}
