import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Transform } from 'class-transformer';

export abstract class AbstractBean {

  public id: any;
  @Transform(({value}) => EntityTypeEnum[value])
  public entityType: EntityTypeEnum;
  public version: number;
  public name: string;
  public selectedLanguage: number;

}
